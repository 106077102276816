// eslint-disable-next-line import/prefer-default-export
export const HealthBeautyHero = {
  title: "Simple, powerful appointment software with a personal touch",
  subtext:
    "Book more appointments and streamline the checkout process with an end-to-end solution designed for salons, spas, medical practices, and more.",
  heroImg: "hb-lp.jpg",
  imageClassName: "smaller-hero",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/health-and-beauty-a/demo",
  },
  fatTitle: "",
};

export const HealthBeautyTrusted = [
  {
    title: "30 - 40%",
    subtext: "of increase in sales with online booking",
  },
  {
    title: "40%",
    subtext: "of decrease in no-shows with automated reminders",
  },
  {
    title: "31%",
    subtext: "of increase in sales with repeat clients",
  },
];

export const HealthBeautyWhiteGlove = {
  mainTitle: "Backed by real people",
  title: "Personalized support from people who care",
  content:
    "You work hard to give your clients the best service. At SpotOn, we do the same. Our team of experts will work closely with you to assess your needs and create a solution that makes life easier for you, your employees, and your clients. We’ll teach you how to get the most from your new technology, and we’ll continue to earn your business every day with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/health-and-beauty-a/demo",
  },
};

export const HealthBeautyLargeFeatures = {
  sectionTitle: "Convenient and efficient tech for you and your clients",
  featureBlocks: [
    {
      blockTitle: "More ways to book appointments. Fewer no-shows",
      blockList: [
        "Online booking from your website and Facebook page, available 24/7",
        "Text & email reminders to cut down on no-shows",
        "Customer deposits & prepayments to improve cash flow and minimize losses",
        "2-way texting with clients to keep it personal",
        "Digital marketing & loyalty to attract new clients and keep regulars coming back",
      ],
      blockImg: "hb-lp-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/health-and-beauty-a/demo",
      },
    },
    {
      blockTitle: "Make checkout & invoicing easy",
      blockImg: "hp-lp-b.png",
      blockList: [
        "Accept secure payments in-person, including Apple Pay and Google Pay",
        "Run one-time & recurring transactions electronically",
        "Save time with built-in tools to work more efficiently,including card-on-file",
        "Send invoices, digital receipts, and automatic payment reminders",
        "Upgrade the payment experience with a sleek handheld device",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/health-and-beauty-a/demo",
      },
    },
    {
      blockTitle: "Powerful tools to do business your way",
      blockList: [
        "Work smarter with better analytics and reporting",
        "Grow your customer list with integrated marketing software",
        "Send digital coupons, emails, and Facebook campaigns",
        "Monitor your online reputation on Yelp and Google",
        "Add optional loyalty rewards to drive repeat business",
      ],
      blockImg: "hp-lp-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/lp/health-and-beauty-a/demo",
      },
    },
  ],
};

export const HealthBeautyTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const HealthBeautyCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/lp/health-and-beauty-a/demo",
  },
};
