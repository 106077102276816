import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import HeroForm from "../../../components/Hero/heroForm";
import {
  HealthBeautyHero,
  HealthBeautyWhiteGlove,
  HealthBeautyLargeFeatures,
  HealthBeautyCTA,
  HealthBeautyTestimonials,
  HealthBeautyTrusted,
} from "../../../data/landing/health-and-beauty-a-data";
import heroBg from "../../../images/hero-images/home-decor-hero.png";
import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";

// const NoVisualsCustom = loadable(() =>
//   import("../../../components/NoVisuals/NoVisualsCustom")
// );

const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const PartnerLogos = loadable(() =>
  import("../../../components/PartnerLogos/PartnerLogos")
);

const whiteGloveImport = "hb-whiteglove.png";

const HealthBeauty = () => {
  return (
    <Layout isLanding phoneNumber="+13035240930" phoneText="(303) 524-0930">
      <SEO
        title="Salon management | Appointments and Management Software | SpotOn"
        description="Book more appointments and streamline the checkout process with an end-to-end solution designed for salons, spas, medical practices, and more."
        image={`https://spoton.com/${heroBg}`}
      />
      <HeroForm
        sectionData={HealthBeautyHero}
        formId="fcb088ff-731b-48b8-96dd-cca8f1f66504"
        formHeader="Simple, powerful appointment software with a personal touch"
        fromSubheader="Book more appointments and streamline the checkout process with an end-to-end solution designed for salons, spas, medical practices, and more."
        thankYouPath="/lp/health-and-beauty-a/thank-you"
        leadType="lead-gen"
      />
      <TrustedNumbers numbersArray={HealthBeautyTrusted} />
      <section style={{ marginTop: "40px" }}>
        <TestmonialReviews sectionData={HealthBeautyTestimonials} />
      </section>
      <PartnerLogos />
      <LargeFeatures
        sectionData={HealthBeautyLargeFeatures}
        style={{ marginTop: "128px !important" }}
      />
      <WhiteGlove
        sectionData={HealthBeautyWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeCta sectionData={HealthBeautyCTA} />
    </Layout>
  );
};

export default HealthBeauty;
